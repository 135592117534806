
import { defineComponent } from "vue";
import variables from "@/router/api";
import ApiService from "@/core/services/ApiService";
import $ from "jquery";

export default defineComponent({
  name: "email-verification",
  components: {},
  data() {
    return {
      appName: "IBN WealthPro",
      firstName: this.$route.params.firstName,
      lastName: this.$route.params.lastName,
      email: this.$route.params.email,
    };
  },
  created() {
    //Set page title
    document.title = "Email Verification | " + process.env.VUE_APP_TITLE;
  },
  mounted() {
    if ($.trim(this.email).length == 0) {
      this.$router.push({ name: "sign-in" });
    }
  },
  methods: {
    resendVerification() {
      ApiService.post(variables.RESEND_VERIFY_EMAIL_ROUTE, {
        emailAddress: this.email,
      })
        .then((response) => {
          if (response.data.error == undefined) {
            variables.toastAlert(response.data.message, "success");
          } else {
            variables.toastAlert(response.data.error, "error");
          }
        })
        .catch(({ response }) => {
          variables.toastAlert(response.data.error, "error");
        });
    },
  },
});
